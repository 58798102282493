@include mobile {
  .ProductPage {
    padding-bottom: 0 !important;

    .ProductActions-Title {
      display: none;
    }

    .ProductActions-MobileTitle {
      display: block;
      order: 10;
      text-align: center;

      .ProductActions-Title {
        display: block;
      }
    }
  }

  .ProductLinks-List{
    display: flex !important;

    .ProductCard {
      width: 50%;

      .ProductActions-Title {
        display: block;
      }
    }
  }

  .ProductGallery {
    order:20 !important;
    padding-bottom: 3rem;
  }

  .Slider {
    padding-bottom: 3rem;

    &-Crumbs {
      bottom: 5px !important;
    }
  }

  .ProductActions {
    order: 30 !important;
  }
}