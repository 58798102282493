
@include mobile {
    .BannerGroup {
        &-Container {
            &.ContentWrapper_isFluid {
                .BannerItem {
                    &-Content {
                        display: none;
                    }
        
                    &-ContentBlock {
                        padding-bottom: 7rem !important;
                    }
                }
            }
        }
    }

    .swiper {
        &-container {
            .swiper {
                &-button {
                    &-prev, &-next{
                        bottom: 1.5rem !important;
                        transform: none !important;
                    }

                    &-prev {
                        right: auto;
                        left: 50%;
                        transform: none !important;
                        margin-left: -45px;
                    }

                    &-next {
                        right: 50%;
                        left: auto;
                        transform: none !important;
                        margin-right: -45px;
                    }
                }
            }
        }
    }
}