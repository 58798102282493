:root {
  --custom-border-color: #DDDDDD;
}

// overwrite drunk base code
li:last-child {
  margin-bottom: 6px;
}

a + a {
  margin-left: auto;
}