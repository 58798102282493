@import './global';
@import './product-page';
@import './banners';
@import './banner-slider';
@import './euarchery-global';

.CategoryFilterOverlay-Filter_type_price {
    display: none;
}

.CookiePopup {
    display: none !important;
}
