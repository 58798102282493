:root {
    --breadcrumbs-background: #EEE !important;
}

.HeaderMenu {
    &-MenuWrapper {
        --header-menu-main-item-margin-after-tablet: 0 3% 0 0;
    }
}

.SearchField {
    --search-bar-max-width-desktop: 220px;
}

.Footer {
    --footer-column-content-color: var(--secondary-light-color);
    --footer-column-title-color: white;
    --footer-column-title-font-size: 2rem;
    --footer-column-title-font-weight: 700;
    --footer-column-title-text-transform: uppercase;
    --footer-content-background-color: var(--primary-base-color);
    background: darken(#003e89, 10%);

    @include mobile {
        --footer-column-content-background-color: #002756;
        --footer-column-title-font-size: 1.6rem;
        --footer-column-title-text-transform: none;
        --footer-column-newsletter-background-color: var(--secondary-base-color);
        --footer-newsletter-button-background-color-mobile: var(--primary-base-color);
    }

    &-Content {
        --footer-content-height: auto;
    }

    &-Columns {
        flex-wrap: wrap;
        justify-content: space-between;
    }

    &-Column {
        flex: 1 1 calc(100% - 2rem);
        margin-bottom: 2rem;

        @include desktop {
            margin-right: 0 !important;
            flex: 1 1 calc(50% - 2rem);
            max-width: 100% !important;
        }

        @include after-tablet-landscape {
            flex: 1 1 calc(25% - 2rem);
            margin-bottom: 0;
        }
    }
}

.FooterMenu {
    &-List {
        --footer-menu-link-color: var(--footer-column-content-color);
    }
}

.StoreSocial {
    &-List {
        --store-social-list-icon-color: #fff;
    }

    &-ListIcon {
        a {
            transition: 200ms;

            &:hover {
                opacity: 0.7;
            }
        }
    }
}

.BannerGroup {
    --banner-block-item-height-col-2: auto;
    --banner-block-item-height-col-3: auto;
    --banner-block-item-height-col-4: auto;
    --banner-block-item-height-col-5: auto;
    --banner-block-item-height-col-6: auto;

    @include mobile {
        --banner-block-item-height-col-2: auto;
        --banner-block-item-height-col-3: auto;
        --banner-block-item-height-col-4: auto;
        --banner-block-item-height-col-5: auto;
        --banner-block-item-height-col-6: auto;
    }

    @include tablet {
        --banner-block-item-height-col-3: auto;
        --banner-block-item-height-col-2: auto;
        --banner-block-item-height-col-4: auto;
        --banner-block-item-height-col-5: auto;
        --banner-block-item-height-col-6: auto;
    }
}

.BannerItem {
    --banner-item-title-font-size: 1.6rem;
    --banner-item-content-padding: 0 2rem 2rem 2rem;

    &_isSlider {
        .BannerItem-ContentBlock {
            --banner-item-content-padding: 0 2rem 6rem 2rem;
        }
    }

    &_isGradient::after,
    &-Title,
    &-ContentBlock {
        pointer-events: none;
    }
}

.SiteUsp {
    &-Content {
        --site-usp-font-size: 1.3rem;
    }

    &-List {
        --site-usp-icon-color: #EA5800;
    }

    &-Icon {
        font-size: 2rem !important;
    }
}

.NewsletterSubscription {
    .FieldForm {
        &-Fieldset .Field input {

            &:focus {
                border-color: #fff !important;
            }
        }

        &-Button {
            background-color: #EA5800 !important;
            border-color: #EA5800 !important;

            &:hover {
                background-color: lighten(#EA5800, 10%) !important;
                border-color: lighten(#EA5800, 10%) !important;
            }
        }
    }
}

.SearchField-SearchInnerWrapper .SearchField-SearchIcon {
    transition: 200ms;

    &:hover {
        opacity: 0.7;
    }
}

.NoMatch {
    &-Wrapper {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
}

.SearchField {
    &-Input {

        &:focus,
        &:active {
            border-bottom-color: var(--primary-base-color) !important;
        }
    }
}

.CategoryPage-Wrapper,
.CategoryPage,
.ProductPage,
.Checkout {
    margin-top: 0 !important;
}

