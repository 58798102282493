.BannerSlider {
  @include mobile {
    --banner-slider-height: 30rem !important;

    &-Container {
      &_type_column_1 {
        --banner-slider-height: 30rem !important;
      }
    }
  }
}
