@import 'variables';

.Header {
    &-MinicartItemCount {
        background: #EA5800 !important;
        color: #fff !important;
        padding:0;
        width:2rem !important;
        height:2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        right: -1rem;
        top: -1rem;
        padding: 0.3rem 0 !important;

        @include mobile {
            right: -1rem;
            top: -0.6rem !important;
            left: auto !important;
        }
    }

    @include mobile {
        &-Wrapper {
            --header-wrapper-height: calc(var(--header-bottom-height) + 92px);
        }
    }
}

.CmsPage .BannerGroup-Container.BannerGroup-Container_isFullWidth {
    .BannerItem {
        &-Title {
            --banner-item-title-font-size: 2.4rem;
            text-shadow: 0px 1px 4px #000000;
            text-transform: uppercase;
            font-style: italic;
            color: #fff;
        }

        &-Content {
            color: #ECECEC;
            text-shadow: 0px 2px 1px #00000029;
            font-weight: 300;
            line-height: 1.75;
            font-size: 1.6rem;
            margin-bottom: 1rem;
        }
        
        &-Link {
            background-color: #EA5800;
            border: 0;
            color: #fff;
            border-radius: 2.5px;
            font-size: 1.4rem;
            line-height: 1.1;
        }
    }

    @include mobile {
        .BannerItem {
            &_isSlider .BannerItem-ContentBlock {
                --banner-slider-item-content-padding: 0 1.5rem 6rem 1.5rem;

            }

            &-Content {
                margin-bottom: 1.5rem;
            }
        }

        .swiper-button-prev {
            left: auto;
            right: 50%;
            transform: translateX(-50%);
            margin-right: 15px;
        }

        .swiper-button-next {
            right: auto;
            left: 50%;
            transform: translateX(-50%);
            margin-left: 15px;
        }
    }

    @include desktop {
        .BannerItem {
            &-Title {
                --banner-item-title-font-size: 5rem;
            }

            &-Content {
                --banner-item-max-content-width: 88rem;
                font-size: 2rem;
            }
        }
    }
}

@include desktop {
    .Header-Wrapper {
        .Header_name_cart_overlay {
            .Header-MinicartItemCount {
                color: white;
            }
        }

        .CartOverlay {
            top: calc(var(--header-logo-height) - 10px);
            --overlay-desktop-border-color: #efefef;

            &-Promo {
                display: none;
            }

            &_isVisible {
                border: 1px solid #efefef;
            }
        }
    }
}

.CartPage {
    .CartPage-TaxRate {
        display: none;
    }
}

.HomePageInfo {
    max-width: var(--content-wrapper-width);
    margin: 0 auto 2rem auto;
    padding: 0 1.5rem;

    @include desktop {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 3rem;
    }

    &-Column {
        margin-bottom: 2rem;
        
        @include desktop {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 2.5rem;
            margin: 0;
        }

        &_one {
            @include desktop {
                grid-template-columns: 1fr;
            }
        }
    }

    &-Block {
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);

        @include mobile {
            &:first-of-type {
                margin-bottom: 2rem;
            }
        }

        .HomePageInfo-Column_one & {
            padding: 3rem;

            h1 {
                font-style: italic;

                strong {
                    color: var(--secondary-base-color);
                }
            }
            
            p {
                font-size: 1.5rem;
                line-height: 3rem;
                font-weight: 300;
            }
        }

      
        &Content {
            padding: 2rem;
            
            img {
                width: 100% !important;
                height: auto;
            }

            li {
                @include reset-list-item;
                line-height: 3rem;
                font-size: 1.6rem;
                display: flex;
                justify-content: flex-start;

                span {
                    font-weight: bold;
                    width: 160px;

                    + span {
                        font-weight: normal;
                    }
                }
            }

            .phone {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding-top: 1.6rem;
                margin-top: 1.8rem;
                border-top: 1px solid var(--secondary-light-color);
                font-size: 1.6rem;

                i {
                    margin-right: 1rem;
                    font-size: 3rem;
                    color: var(--secondary-base-color);
                }
            }
        }
    }
}

.BannerGroup-Container {
    .Banner {
        &Item {
            &-Title {
                line-height: 1.1;
                margin: 0;
                text-transform: uppercase;
            }
            
            &-ContentBlock {
                position: absolute;
                bottom: 0;
            }
        }
        
        &Block {
            &-Item_type_column_3 {
                min-height: 0;
                height: auto;
                padding-bottom: 66%;
            }
        }
    }
}

.SaasSliderWidget {
    width: 100%;
    max-width: var(--content-wrapper-width);
    margin: 0 auto 4rem auto !important;
    
    @include desktop {
        padding: 0 1.5rem;
    }
    
    &-Heading {
        text-transform: uppercase !important;
        font-style: italic;
        font-size: 3rem !important;
        line-height: 1.2;
        margin-top: 0;
        margin-bottom: 2rem;
    }
    
    &-Slide {
        padding: 0.3rem;
    }
    
    &-SaasSlider {
        position: relative;
        height: 100%;
        display: flex;
        
        .ProductCard {
            box-shadow: 0 0 4px rgba(0, 0, 0, 0.1) !important;
    
            &-Name {
                font-size: 1.6rem;
                margin-bottom: 2.5rem;
                height: 6rem;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }
            
            &:last-child {
                margin-bottom: 0 !important;
            }
            
            &-ConfigurableOptions {
                display: none;
            }
            
            &-Price {
                font-size: 2rem;
                color: #EA5800;
            }
        }
    }
}

.ProductCard,
.ProductListWidget .ProductCard {
    .SaasProductTitleBlock h2 {
        font-weight: 400;
    }
}

.HomePage .LayoutGroupWidget {
    &-Container {
        .LayoutGroupWidget {
            &-Item {
                box-shadow: 0 0 4px rgba(0, 0, 0, 0.1) !important;
            }
        }
    }
}

.Field {
    &_isValid {
        input,
        textarea {
            --primary-success-color: var(--secondary-base-color);
        }
    }
}

.Button {
    transition-duration: 200ms;
}

.MyAccount {
    &-Wrapper {
        padding-top: 2rem !important;
        padding-bottom: 2rem !important;
    }
}

.SearchField {
    &-SearchWrapper {
        left: 0;
        right: auto;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.1) !important;
    }
}

.StoreSwitcher {
    &-StoreList {
        left: 0 !important;
        right: auto !important;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.1) !important;
    }
}

.CategorySeoContent {
    &:empty {
        display: none;
    }
}

.SectionNavigation-Wrapper {
    margin-bottom: 4rem !important;
}

.ProductActions {
    .SiteUsp {
        &-Icon {
            text-align: center;
            width: 2.5rem;
        }
    }

    &-Title {
        text-transform: inherit;
    }
}

.BannerItem {
    &_isSlider {
        .BannerItem {
            &-ContentBlock {
                max-width: var(--content-wrapper-width);
                margin: auto auto 0 auto !important;
                position: relative !important;
            }
        }
    }
}

@include mobile {
    .CmsPage {
        &-Content {
            padding:0 1.5rem;
        }
    }

    .SaasSliderWidget {
        margin-bottom: 1.5rem !important;
        margin-left: -1.5rem !important;
        margin-right: -1.5rem !important;
        width: calc(100% + 3rem) !important;
        max-width: none !important;

        .swiper {
            &-container {
                &-horizontal {
                    padding-bottom: 5rem !important;
                }
            }
        }

    }
}